<template >
  <q-expansion-item
    class="document-comment"
    label="Комментарий"
  >
    <div class="document-comment__body">
        {{comment}}
    </div>


  </q-expansion-item>
</template>

<script>
export default {
  props: ['comment']
}
</script>

<style lang="scss">
  .document-comment {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    background: #FFFFFF;
    border-radius: 15px;
    width: 300px;
    align-self: flex-start;
    @include media-down($breakpoint-md) {
      width: 100%;
    }
  }

  .document-comment__title {
    color: $black-02;
  }  
  .document-comment__body {
    color: $black-03;
    padding: 0 25px 17px 16px;

  }  
</style>