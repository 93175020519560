import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { getDocumentStatus } from '@/utils/docStatus.js';
import DialogModal from '@/components/modals/DialogModal.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { pluralizeText } from '@/utils/utils';
import { getImageLink } from '@/utils/utils';
import BaseStatus from '@/components/UI/BaseStatus.vue';
import { downloadDocs } from '@/utils/downloadDocs.js';
let FileItem = class FileItem extends Vue {
    constructor() {
        super(...arguments);
        this.isDialogModalOpen = false;
    }
    get fileStatus() {
        return getDocumentStatus([this.file]);
    }
    get resultsQuantity() {
        const ru = ['анализ', 'анализа', 'анализов'];
        return `${this.file.results_count} ${pluralizeText(this.file.results_count, ru)}`;
    }
    get getImageLink() {
        return getImageLink;
    }
    toggleDialogModal(val) {
        this.isDialogModalOpen = val;
    }
    async deleteDocument() {
        const isResult = await this.$store.dispatch('storage/deleteFile', {
            documentId: this.documentId,
            fileId: this.file.id,
        });
        isResult && this.toggleDialogModal(false), this.$store.dispatch('storage/loadDocument', this.documentId);
    }
    downloadFile() {
        const { id } = this.file;
        downloadDocs(this.file.file_link, id);
    }
    toggleFileListSliderModal() {
        return true;
    }
};
__decorate([
    Prop()
], FileItem.prototype, "file", void 0);
__decorate([
    Prop({ required: true })
], FileItem.prototype, "documentId", void 0);
__decorate([
    Prop()
], FileItem.prototype, "docStatus", void 0);
__decorate([
    Prop()
], FileItem.prototype, "allowProcessing", void 0);
__decorate([
    Emit('toggle-file-list-slider-modal')
], FileItem.prototype, "toggleFileListSliderModal", null);
FileItem = __decorate([
    Component({ components: { DialogModal, MainBtn, BaseStatus } })
], FileItem);
export default FileItem;
