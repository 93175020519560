import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
// import AnalyzesCard from '@/components/analyzes/AnalyzesCard.vue';
import { mapGetters } from 'vuex';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
let AddBiomarkerModal = class AddBiomarkerModal extends Vue {
    constructor() {
        super(...arguments);
        this.searchedValue = '';
        this.searchedList = [];
        this.timeout = 0;
        this.loading = false;
    }
    mounted() {
        this.$store.dispatch('analyzes/getLaboratories');
    }
    searchAnalyzes(val, oldVal) {
        clearTimeout(this.timeout);
        if (val !== oldVal) {
            this.loading = true;
            this.timeout = setTimeout(async () => {
                this.searchedList = await this.$store.dispatch('analyzes/getBiomarkers', { query: val });
                this.loading = false;
            }, 1000);
        }
    }
    get laboratoriesList() {
        return this.$store.state.staticVariables.labs;
    }
    get biomarkersList() {
        return this.$store.state.analyzes.biomarkersList;
    }
    get changingAnalyzeId() {
        return this.$store.state.analyzes.changingAnalyzeId;
    }
    async addAnalyzes(item) {
        if (this.changingAnalyzeId) {
            const docId = this.$route.params.id;
            this.$store.dispatch('documents/updateBiomarkerInResult', { item, biomarkerId: this.changingAnalyzeId, docId });
            this.closePopup();
            return;
        }
        this.$store.commit('analyzes/addAnalyzes', this.generateItemForAnalyzes(item));
        this.searchedValue = '';
    }
    generateItemForAnalyzes(item) {
        return {
            biomarker: item.name,
            available_units: item.available_units,
            biomarker_group_id: item.id,
            id: Math.random().toString(36).substr(2, 9),
            laboratory: '',
            value: '',
            date: new Date(),
            comment: null,
            unit: item.unit,
            unit_id: item.unit_id,
            ranges: item.ranges,
        };
    }
    closePopup() {
        this.$store.commit('analyzes/setPropertyInStore', { name: 'editAnalyzeModalState', value: false });
    }
    onClose() {
        return false;
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], AddBiomarkerModal.prototype, "isPopup", void 0);
__decorate([
    Watch('searchedValue')
], AddBiomarkerModal.prototype, "searchAnalyzes", null);
__decorate([
    Emit('close')
], AddBiomarkerModal.prototype, "onClose", null);
AddBiomarkerModal = __decorate([
    Component({
        components: {
            MainBtn,
            // AnalyzesCard,
            BackBtn,
        },
        computed: mapGetters({
            addedAnalyzes: 'analyzes/getAddedAnalyzes',
        }),
    })
], AddBiomarkerModal);
export default AddBiomarkerModal;
