import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import BaseStatus from '@/components/UI/BaseStatus.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { IRouter } from '@/interfaces/router.interface';
import { format } from 'date-fns';
import { serverDateFormat } from '@/interfaces/api.interface';
import EditDocumentModal from '@/components/modals/EditDocumentModal.vue';
import { pluralizeText } from '@/utils/utils';
import TooltipActions from '@/components/UI/tooltip/TooltipActions.vue';
import { downloadDocs } from '@/utils/downloadDocs.js';
let InfoBlock = class InfoBlock extends Vue {
    constructor() {
        super(...arguments);
        this.isDialogModalOpen = false;
        this.isEditDocumentModalOpen = false;
    }
    get document() {
        return this.$store.state.storage.document;
    }
    get documentResults() {
        return this.$store.state.documents.documentResults;
    }
    get fileQuantity() {
        const quantity = this.document.files?.length;
        const ru = ['файл', 'файла', 'файлов'];
        return `${quantity} ${pluralizeText(quantity, ru)}`;
    }
    get biomarkerQuantity() {
        const quantity = this.documentResults?.length;
        const ru = ['биомаркер', 'биомаркера', 'биомаркеров'];
        return `${quantity} ${pluralizeText(quantity, ru)}`;
    }
    toggleDialogModal(val) {
        this.isDialogModalOpen = val;
    }
    toggleEditDocumentModal(val) {
        this.isEditDocumentModalOpen = val;
    }
    deleteDocument() {
        const isResult = this.$store.dispatch('storage/deleteDocument', this.document.id);
        isResult && this.toggleDialogModal(false);
        isResult && this.$router.push({ name: IRouter.ROUTE_NAME.STORAGE_PAGE });
    }
    async editDocument(obj) {
        const { name, date, type_doc, laboratory_id, comment } = obj;
        const payload = {
            id: this.document.id,
            name: name ? name : this.document.name,
            date: date ? format(new Date(date), serverDateFormat) : this.document.date,
            type_doc: type_doc ? type_doc : this.document.type_doc,
            laboratory_id: laboratory_id ? laboratory_id : this.document.laboratory_id,
            comment: comment || this.document.comment,
            singleDoc: true
        };
        const isResult = await this.$store.dispatch('storage/editDocument', payload);
        console.log(isResult);
        isResult && this.toggleEditDocumentModal(false);
    }
    downloadFiles() {
        this.document.files.forEach((file) => {
            downloadDocs(file.file_link, file.id);
        });
    }
};
__decorate([
    Prop()
], InfoBlock.prototype, "docStatus", void 0);
InfoBlock = __decorate([
    Component({
        components: { EditDocumentModal, MainBtn, BaseStatus, MessageModal, TooltipActions },
    })
], InfoBlock);
export default InfoBlock;
