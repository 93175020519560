// Выводить каждый раз, когда пользователь попадает на конкретную страницу документа в первый раз
<template>
  <div v-if="isShow" class="document-tip">
    Мы пока только учимся распознавать все без ошибок 💙<br><br>Пожалуйста, перепроверьте данные оцифровки. Изменения можно внести вручную, длительно нажав на биомаркер.
    <div class="cursor-area document-tip__close" @click="isShow = false">
      <icon width="10px" name="close-icon" color="#7C74E9"></icon>
    </div>
  </div>
</template>

<script>
import {userFirstVisit} from '@/utils/utils.ts'

export default {
  data(){
    return {
      isShow: userFirstVisit({localStorageItem: 'closedTips', id: this.$route.params.id })
    }
  }
}
</script>

<style lang="scss">
  .document-tip {
    margin-top: 45px;
    padding: 20px 48px 20px 20px;
    background: #EEF1FF;
    border-radius: 20px;
    color:#2D2D2D;
    font-size: 14px;
    line-height: 18px;
    max-width: 540px;
    position: relative;
    @include media-down($breakpoint-md) {
      margin-right: 30px;
    }
  }
  .document-tip__close {
    position: absolute;
        top: 8px;
    right: -20px;
  }
</style>