import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import InfoBlock from '@/views/DocumentPage/InfoBlock.vue';
import FileItem from '@/views/DocumentPage/FileItem.vue';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import { pluralizeText, userFirstVisit, someOldUnitNotEqualUnit } from '@/utils/utils';
import { getDocumentStatus } from '@/utils/docStatus.js';
import EditAnalyzeModal from '@/components/modals/EditAnalyzeModal.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import FilePicker from '@/components/modals/addFileModal/FilePicker.vue';
import { bus } from '@/plugins/bus';
import AddComment from '@/components/modals/AddComment.vue';
import FileListSliderModal from '@/components/modals/FileListSliderModal.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import AnalyzeTH from '@/components/analyzes/AnalyzeTH.vue';
import AnalyzeItem from '@/components/items/AnalyzeItem.vue';
import AnalyzeForm from '@/components/modals_new/complex/AnalyzeForm.vue';
import DocumentTip from '@/views/DocumentsPage/DocumentTip.vue';
import DocumentComment from '@/views/DocumentsPage/DocumentComment.vue';
import ManualAddition from '@/components/modals_new/complex/ManualAddition.vue';
import ConfirmationModal from '@/components/modals_new/templates/ConfirmationModal.vue';
import ConvertModal from '@/components/modals_new/filled/ConvertModal.vue';
import BaseStatus from '@/components/UI/BaseStatus.vue';
import CommentExpansion from '@/components/UI/CommentExpansion.vue';
import { documentAllStatusesNew } from '@/utils/docStatus.js';
let DocumentPage = class DocumentPage extends Vue {
    constructor() {
        super(...arguments);
        this.fileList = [];
        this.addCommentModalState = false;
        this.isFileListSliderModalOpen = false;
        this.isCommentsShow = false;
        // TODO вынести
        this.analyzeTHData = ['Биомаркер',
            'Значение',
            'Ед. изм.',
            'Референс. значения',
            'Лаборатория',
            'Дата'];
        this.documentAllStatusesNew = documentAllStatusesNew;
        // TODO think
        this.isOpenConvertModal = false;
        this.prevUnit = 'nodata';
        this.newUnit = 'nodata';
        this.isReasonModalOpen = false;
        this.reason = '';
        this.currentInitialSlide = 1;
        // Edit/Remove
        this.formProp = null;
        this.isOpenAnalyzeForm = false;
        this.isShowConfirmationModal = false;
        this.dataConfirmationModal = null;
        this.isShowManualAdditionModal = false;
    }
    async mounted() {
        // TODO refact
        const docId = this.$route.params.id;
        userFirstVisit({ localStorageItem: 'viewedDocs', id: +docId });
        const isResult = await this.$store.dispatch('storage/loadDocument', docId);
        !isResult && (await this.$router.push({ name: ROUTE_NAME.STORAGE_PAGE }));
        bus.$on(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, (id) => {
            this.addCommentModalState = !this.addCommentModalState;
            this.$store.commit('analyzes/setPropertyInStore', { name: 'commentAnalyzesId', value: id });
        });
        await this.$store.dispatch('documents/getDocumentResults', docId);
        await this.$store.dispatch('analyzes/analyzeBiomarkers', {});
        this.checkUntsEquality();
        this.checkBadStatus();
    }
    checkUntsEquality() {
        const { notEqual, oldUnit, jlabUnit } = someOldUnitNotEqualUnit(this.documentResults);
        if (notEqual) {
            this.prevUnit = oldUnit?.name;
            this.newUnit = jlabUnit?.name;
            this.isOpenConvertModal = userFirstVisit({ localStorageItem: 'lastUnitDocs', id: this.$route.params.id });
        }
    }
    checkBadStatus() {
        const badDocStatus = [2, 3].includes(this.document.status);
        if (badDocStatus) {
            this.reason = this.document.files.find(file => file.reason)?.reason;
            if (this.reason && userFirstVisit({ id: this.document.id, localStorageItem: 'closedDocReasons' })) {
                this.isReasonModalOpen = true;
            }
        }
    }
    async destroy() {
        bus.$off(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT);
    }
    get sortedDocuments() {
        if (this.document.files) {
            const sortFunc = (a, b) => {
                if (a.created_at < b.created_at)
                    return 1;
                if (a.created_at == b.created_at)
                    return 0;
                if (a.created_at > b.created_at)
                    return -1;
            };
            return [...this.document.files].sort(sortFunc);
        }
    }
    get document() {
        return this.$store.state.storage.document;
    }
    get documentResults() {
        return this.$store.state.documents.documentResults; // TODO подумать стора нужна? если нет выпилить
    }
    get docStatus() {
        return getDocumentStatus(this.document.files);
    }
    get analyzeBiomarkerList() {
        return this.$store.state.analyzes.analyzeBiomarkerList;
    }
    get editAnalyzeModalState() {
        return this.$store.state.analyzes.editAnalyzeModalState;
    }
    get units() {
        return this.$store.state.staticVariables.units;
    }
    toggleAnalyzeModal(value) {
        this.$store.commit('analyzes/setPropertyInStore', { name: 'editAnalyzeModalState', value });
    }
    validateFile(file) {
        return file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf';
    }
    async addFiles(files) {
        try {
            [...files].forEach((file) => {
                this.validateFile(file)
                    ? this.fileList.push(file)
                    : this.$store.dispatch('error/showErrorNotice', { message: 'Неверный формат файла' }, { root: true });
            });
            const payload = {
                id: this.document?.id,
                fileList: this.fileList,
            };
            const isFileCreated = await this.$store.dispatch('storage/createFiles', payload);
            isFileCreated && this.$store.dispatch('storage/loadDocument', this.document?.id);
            this.fileList = [];
        }
        catch (error) {
            throw Error(error);
        }
    }
    addCommentClose(value) {
        this.addCommentModalState = value;
    }
    toggleFileListSliderModal(val) {
        this.isFileListSliderModalOpen = val === false ? false : true;
        this.currentInitialSlide = val + 1;
    }
    openAnalyzeForm(formProp) {
        this.formProp = formProp;
        this.isOpenAnalyzeForm = true;
    }
    closeAnalyzeForm() {
        this.formProp = null;
        this.isOpenAnalyzeForm = false;
    }
    updateAddedAnalyze({ data, isNew }) {
        console.log(data, isNew);
        if (isNew) {
            this.documentResults.push(data);
        }
        else {
            const editableItemIndex = this.documentResults.findIndex(item => item.id === data.id);
            this.documentResults.splice(editableItemIndex, 1, data);
        }
    }
    showDeleteModal(data) {
        this.isShowConfirmationModal = true;
        this.dataConfirmationModal = data;
    }
    async deleteAddedAnalyze() {
        const res = await this.$store.dispatch('analyzes/deleteBiomarker', this.dataConfirmationModal.id);
        if (res?.status === 200) {
            const editableItemIndex = this.documentResults.findIndex(item => item.id === this.dataConfirmationModal.id);
            this.documentResults.splice(editableItemIndex, 1);
        }
        this.isShowConfirmationModal = false;
    }
    get biomarkerQuantity() {
        const quantity = this.documentResults?.length;
        const ru = ['биомаркер', 'биомаркера', 'биомаркеров'];
        return `${quantity} ${pluralizeText(quantity, ru)}`;
    }
    get changingAnalyzeId() {
        return this.$store.state.analyzes.changingAnalyzeId;
    }
    async addAnalyzes(item) {
        this.$store.dispatch('documents/updateBiomarkerInResult', { item, biomarkerId: this.changingAnalyzeId, docId: this.$route.params.id });
    }
    get isAnalyze() {
        return this.document.type_doc === 1;
    }
    get docTypeTitle() {
        return this.$store.state.staticVariables.staticVariables?.document_types[this.document.type_doc]?.description;
    }
};
DocumentPage = __decorate([
    Component({
        components: {
            FileItem,
            InfoBlock,
            EditAnalyzeModal,
            FilePicker,
            AddComment,
            FileListSliderModal,
            BackBtn,
            AnalyzeItem,
            AnalyzeForm,
            DocumentTip,
            DocumentComment,
            MainBtn,
            ManualAddition,
            ConfirmationModal,
            AnalyzeTH,
            ConvertModal,
            BaseStatus,
            MessageModal,
            CommentExpansion
        },
    })
], DocumentPage);
export default DocumentPage;
